import styled from '@emotion/styled'
import { graphql } from "gatsby"
import React, { useMemo } from "react"

import Layout from "../components/layout"
import { FeedLink } from '../components/links'
import PostList from "../components/postList"
import Seo from "../components/seo"
import { parseDate } from "../utils/date"
import { getResolvedVersionForLanguage } from '../utils/node'

const YearContainer = styled.div`
  h3 {
    padding-bottom: 0.7rem;
    color: ${({ theme }) => theme.archives.year.textColor};
    margin: 3rem 0 0;
  }
`

const StyledPostList = styled(PostList)`
  font-size: 1.3rem;
`

const Title = styled.h1`
  a {
    font-size: 80%;
    margin-left: 0.2em;
  }

  svg {
    width: 1em;
    height: 1em;
  }
`

const Page = ({ data }) => {
  // sort blog post and categorize by year
  const postsByYear = useMemo(() => {
    const blogPosts = data.allMarkdownPage.nodes
      .map(n => {
        return {
          ...getResolvedVersionForLanguage(n.versions, n.lang, n.lang),
          path: n.path,
        }
      })

    const categorized = {}

    for (const post of blogPosts) {
      const { year } = parseDate(post.date)

      if (!categorized[year]) {
        categorized[year] = [post]
      } else {
        categorized[year].push(post)
      }
    }

    const years = Object.keys(categorized)
    years.sort().reverse()

    const ret = []

    for (const year of years) {
      ret.push({ year, posts: categorized[year] })
    }

    return ret
  }, [ data ])

  return (
    <Layout>
      <Seo title='Blog' />
      <Title>Blog <FeedLink noText={true} /></Title>
      {postsByYear.map(( { year, posts }) => (
        <YearContainer key={year}>
          <h3>{year}</h3>
          <StyledPostList posts={posts} />
        </YearContainer>
      ))}
    </Layout>
  )
}

const Template = ({ data }) => {
  return (
    <Page data={data} />
  )
}

export default Template

export const pageQuery = graphql`
  fragment MarkdownPageFields on MarkdownPage {
    path
    type
    lang
    versions {
      lang
      date
      title
      summary
      markdown
      ogi
    }
  }

  query {
    allMarkdownPage(filter: { type: { eq: "blog" }, draft: { ne: true } }, sort: { order:DESC, fields: date }) {
      nodes {
        ...MarkdownPageFields
      }
    }
  }
`
